<template>
    <div class="zhadminadd">
        <table border="1"  align="center" cellpadding="10" style="width: 100%;"  cellspacing="0">
            <tr>
                <td>事项</td>
                <td>内容</td>
                <td>判断</td>
                <td>操作</td>
            </tr>
            <tr v-for="(item, index) in Testlist" :key="'Testlist' + index">
             <td colspan="1"><el-input v-model="item.Title"></el-input></td> <!--:rowspan="item.Index" v-if="(item.Index !== 0)"-->
             <td colspan="1"><el-input v-model="item.Price"></el-input></td>
             <td colspan="1"><el-input v-model="item.Priceisbool"></el-input></td>
             <td colspan="1" class="tdd" style="min-width: 150px;"><el-button type="primary"  @click="addDomainmax(item,index)" size="mini">{{ $t('All.新增') }}</el-button><el-button type="primary" v-show="Testlist.length > 1"  @click="removeDomainmax(item)" size="mini">{{ $t('All.删除') }}</el-button></td>
           </tr>
        </table>
    </div>
  </template>
<script>
export default {
  data () {
    return {
      Testlist: [
        {
          //
          Numbers: '',
          //
          Index: 0,
          //
          Title: '',
          //
          Price: '',
          //
          Priceisbool: ''
        }
      ],
      tel: ''
    }
  },
  created () {
  },
  mounted () {

  },
  methods: {
    addDomainmax (item, index) {
      const contr = {
        Number: item.Number,
        Index: index + 1,
        Title: item.Title,
        Standard: '',
        Tool: '',
        Benchmark: '',
        Determinetype: '',
        Determine: '',
        Remarks: ''
      }
      this.Testlist.splice(index + 1, 0, contr)
      for (let i = 0; i < this.Testlist.length; i++) {
        this.Testlist[i].Index = i
      }
    },
    removeDomainmax (item) {
      var index = this.Testlist.indexOf(item)
      if (index !== -1) {
        this.Testlist.splice(index, 1)
      }
      for (let i = 0; i < this.Testlist.length; i++) {
        this.Testlist[i].Index = i
      }
    }
  }
}
</script>
<style lang="less" scoped>
</style>
